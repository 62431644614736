import axios from 'axios'


const e_sign = axios.create({
    baseURL: "https://newsign.sunrise-resorts.com/app/v1/api/",

    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'

    },
})

const hr_sing = axios.create({
    baseURL: "https://hrsign.sunrise-resorts.com/app/v1/api/",

    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'

    },
})

const srp_sing = axios.create({
    baseURL: "https://srp.sunrise-resorts.com/app/v1/api/",

    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',

    },
})

// const payload_token = { 'merchant_token': localStorage.getItem('e_sign_token') }; 

export default {


    // ? E-sing Api
    login(item) {
        return e_sign.post('protalLogin', item);
    },
    e_sign_dashBoardCount() {
        return e_sign.post('updateDashboardCounter', { 'merchant_token': localStorage.getItem('e_sign_token') })
    },
    e_sign_in_singing_module_form(module_name) {
        let payload = {
            'merchant_token': localStorage.getItem('e_sign_token'),
            module_name
        };
        return e_sign.post('portal_in_singing_module_form', payload);
    },
    e_sign_form_details(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        return e_sign.post('module_form_list_id/' + data.form_id, data)
    },
    e_sign_action_sign(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return e_sign.post('portal_sign', data)
    },
    e_sign_action_reject(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return e_sign.post('portal_reject', data)
    },
    e_sign_action_reback(item) {
        item['merchant_token'] = localStorage.getItem('e_sign_token')
        return e_sign.post('reback',);
    },
    e_sign_add_comment(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return e_sign.post('add_comment', data);
    },
    e_sign_modules() {
        return e_sign.get('get_modules');
    },
    e_sign_module_count(modal_id) {
        let payload = {
            'merchant_token': localStorage.getItem('e_sign_token'),
        };
        return e_sign.post('get_modules_count/' + modal_id, payload);
    },

    // ? End E-sign Api

    // ? Start Hr Api
    hr_modules() {
        return hr_sing.get('get_modules');
    },
    hr_module_count(modal_id) {
        let payload = {
            'merchant_token': localStorage.getItem('e_sign_token'),
        };
        return hr_sing.post('get_modules_count/' + modal_id, payload);
    },
    hr_in_singing_module_form(module_name) {
        let payload = {
            'merchant_token': localStorage.getItem('e_sign_token'),
            module_name
        };
        return hr_sing.post('portal_in_singing_module_form', payload);
    },
    hr_form_details(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        return hr_sing.post('module_form_list_id/' + data.form_id, data)
    },
    hr_action_sign(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return hr_sing.post('portal_sign', data)
    },
    hr_action_reject(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return hr_sing.post('portal_reject', data)
    },
    hr_add_comment(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return hr_sing.post('add_comment', data);
    },
    // ? End Hr Api


    // ? Start Srp Api
    srp_modules() {
        return srp_sing.get('get_modules');
    },
    srp_module_count(modal_id) {
        let payload = {
            'merchant_token': localStorage.getItem('e_sign_token'),
        };
        return srp_sing.post('get_modules_count/' + modal_id, payload);
    },
    srp_in_singing_module_form(module_name) {
        let payload = {
            'merchant_token': localStorage.getItem('e_sign_token'),
            module_name
        };
        return srp_sing.post('portal_in_singing_module_form', payload);
    },
    srp_form_details(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        return srp_sing.post('module_form_list_id/' + data.form_id, data)
    },
    srp_action_sign(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return srp_sing.post('portal_sign', data)
    },
    srp_action_reject(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return srp_sing.post('portal_reject', data)
    },
    srp_add_comment(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return srp_sing.post('add_comment', data);
    },

}
