import service from '@/service';
import State from '@/views/forms/input/State.vue';
import { toRaw } from 'vue';


export default {

    state: {
        // !Modules
        E_sign_modules: [],
        Hr_modules: [],
        Srp_modules: [],
        // !Login STate
        user: null,
        user_login: false,
        // !DashBoard Counter
        E_sign_DashBoard: [],

        // ! Check Module working
        Module_is_Available: true,
        Available_Modules: [
            'Projects',
        ],
        // ! Module Index List Actions
        Current_module_name: null,
        E_sign_Forms_action: {
            in_sign_forms: {
                name: 'In Signing',
                color: "#4669FA",
                data: []
            },
            approved: {
                name: 'Approved',
                color: "#50C793",
                data: []
            },
            reject: {
                name: 'Rejected',
                color: "#FA916B",
                data: []
            }
        },
        // !Form Details
        FormDetails: null,
    },
    getters: {
        get_user: () => localStorage.getItem('active_user'),
        available_module: (state) => {
            if (!state.Available_Modules.includes(state.Current_module_name)) {
                return false;
            }
            return true;
        },
        E_sign_move: (state) => (from, to, form_id) => {
            let itemIndex = state.E_sign_Forms_action[from].data.findIndex(item => item.id == form_id);
            if (itemIndex > -1) {
                //  Copy the item
                let item = state.E_sign_Forms_action[from].data[itemIndex];
                // Remove the item from the source array
                state.E_sign_Forms_action[from].data.splice(itemIndex, 1);
                // Add the item to the destination array
                state.E_sign_Forms_action[to].data.push(item);
            }
        },
        e_sign_singer: (state) => {
            let sign
            // console.log(state.FormDetails.signature);
            // console.log(state.FormDetails.signature.length);
            let singers = toRaw(state.FormDetails.signature);
            let keys = Object.keys(singers);
            // console.log(singers);


            for (let index = 0; index < keys.length; index++) {
                if (singers[keys[index]].queue) {
                    // sign = singers[keys[index]];
                    return singers[keys[index]];
                }
            }
            return sign;
        }
    },
    actions: {
        Login({ commit }, item) {
            return service.login(item.item).then(response => {
                commit('SET_USER', response.data)
            });
        },
        E_singDashBoardCounter({ commit }) {
            return service.e_sign_dashBoardCount().then(response => {
                commit('SET_E_SING_DASHBOARD', response.data)
            });
        },
        E_signFormsListInSing({ commit }, item) {
            return service.e_sign_in_singing_module_form(item.module_name).then(response => {
                commit('SET_FORMS_IN_SINGING_DATA', response.data)
                commit('SET_CURRENT_MODULE_NAME', item.module_name)
            });
        },
        E_sign_Form_details({ commit }, item) {
            // console.log(item);
            return service.e_sign_form_details(item).then(response => {
                commit('SET_FORM_DETAILS', response.data)
            })
        },
        E_sign_Action_Sign({ commit }, item) {
            // console.log(item);
            return service.e_sign_action_sign(item);
        },
        E_sign_Action_Reject({ commit }, item) {
            return service.e_sign_action_reject(item);
        },
        E_sign_Action_ReBack({ commit }, item) {
            return service.e_sign_action_reback(item);
        },
        E_sign_add_comment({ commit }, item) {
            return service.e_sign_add_comment(item).then(response => {
                commit('UPDATE_COMMENT', response.data)
            })
        },
        E_sign_get_modules({ commit }) {
            return service.e_sign_modules().then(response => {
                commit('UPDATE_MODULES', response.data)
            });
        },
        E_sign_module_count({ commit }, module_id) {
            return service.e_sign_module_count(module_id);
        },


        // ! HR Actions
        Hr_get_modules({ commit }) {
            return service.hr_modules().then(response => {
                commit('UPDATE_HR_MODULES', response.data)
            });
        },
        Hr_module_count({ commit }, module_id) {
            return service.hr_module_count(module_id);
        },
        Hr_FormsListInSing({ commit }, item) {
            return service.hr_in_singing_module_form(item.module_name).then(response => {
                console.log(response.data);
                
                commit('SET_FORMS_IN_SINGING_DATA', response.data)
                commit('SET_CURRENT_MODULE_NAME', item.module_name)
            });
        },
        Hr_Form_details({ commit }, item) {
            // console.log(item);
            return service.hr_form_details(item).then(response => {
                commit('SET_FORM_DETAILS', response.data)
            })
        },
        Hr_Action_Sign({ commit }, item) {
            // console.log(item);
            return service.hr_action_sign(item);
        },
        Hr_Action_Reject({ commit }, item) {
            return service.hr_action_reject(item);
        },
        Hr_add_comment({ commit }, item) {
            return service.hr_add_comment(item).then(response => {
                commit('UPDATE_COMMENT', response.data)
            })
        },       

        // ! SRP Actions
        Srp_get_modules({ commit }) {
            return service.srp_modules().then(response => {
                commit('UPDATE_SRP_MODULES', response.data)
            });
        },
        Srp_module_count({ commit }, module_id) {
            return service.srp_module_count(module_id);
        },
        Srp_FormsListInSing({ commit }, item) {
            return service.srp_in_singing_module_form(item.module_name).then(response => {
                console.log(response.data);

                commit('SET_FORMS_IN_SINGING_DATA', response.data)
                commit('SET_CURRENT_MODULE_NAME', item.module_name)
            });
        },
        Srp_Form_details({ commit }, item) {
            // console.log(item);
            return service.srp_form_details(item).then(response => {
                commit('SET_FORM_DETAILS', response.data)
            })
        },
        Srp_Action_Sign({ commit }, item) {
            // console.log(item);
            return service.srp_action_sign(item);
        },
        Srp_Action_Reject({ commit }, item) {
            return service.srp_action_reject(item);
        },
        Srp_add_comment({ commit }, item) {
            return service.srp_add_comment(item).then(response => {
                commit('UPDATE_COMMENT', response.data)
            })
        },
    },
    mutations: {
        SET_USER(state, response) {
            if (response.code == 1) {
                state.user_login = true
                localStorage.setItem('activeUser', JSON.stringify(response.details.merchant_info.sessioned_user))
                localStorage.setItem('e_sign_token', response.details.merchant_info.merchant_token)
            }
        },
        SET_E_SING_DASHBOARD(state, response) {
            state.E_sign_DashBoard = response.details.data
            console.log(state.E_sign_DashBoard);
        },
        SET_FORMS_IN_SINGING_DATA(state, response) {
            state.E_sign_Forms_action.in_sign_forms.data = response.details.result
            state.E_sign_Forms_action.approved.data = []
            state.E_sign_Forms_action.reject.data = []
        },
        SET_CURRENT_MODULE_NAME(state, name) {
            state.Current_module_name = name
            console.log(state.Current_module_name);
        },
        SET_FORM_DETAILS(state, result) {
            state.FormDetails = result.details.data
            console.log(state.FormDetails);
        },
        UPDATE_COMMENT(state, result) {
            if (state.FormDetails != null && state.FormDetails.msg) {
                state.FormDetails.msg.unshift(result.details.data.msg[0])
            }
        },
        UPDATE_MODULES(state, result) {
            state.E_sign_modules = result.details.data.modules
        },



        // ! HR 
        UPDATE_HR_MODULES(state , result){
            state.Hr_modules = result.details.data.modules
        },
        

         // ! SRP 
        UPDATE_SRP_MODULES(state, result) {
            state.Srp_modules = result.details.data.modules
            console.log(state.Srp_modules);
            
        }
    },
};
